/* styles.css */
html, body {
    height: 100%; /* Make sure the body takes up full page height */
    margin: 0;
    padding: 0; 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: 0.025em;
}

* {
    box-sizing: border-box;  /* Ensures padding and border are included within the width/height */
}

.container {
    display: flex; /* Enables flexbox layout */
    height: 100vh; /* Full height of the viewport */
}
 
.column1 {
    height: 100%;
    width: 50px; /* Width of the first column */
    background-color: #333; /* Color for visibility */
}

.column2 {
    height: 100%; /* Each column takes full height of the container */
    width: 450px; /* Width of the second column */
    min-width: 450px;
    max-width: 450px;
    background-color: #fafafa; /* Color for visibility */
    flex-direction: column;  /* Aligns children vertically */
    align-items: flex-start; /* Aligns items to the start of the flex container */
}
 
.column2-contacts {
    height: 450px; /* Each column takes full height of the container */
    width: 100%; /* Width of the second column */
    min-width: 100%;
    max-width: 100%;
    background-color: #fafafa; /* Color for visibility */
    flex-direction: column;  /* Aligns children vertically */
    align-items: flex-start; /* Aligns items to the start of the flex container */
}

.column3 {
    display: flex;    
    height: 100vh; /* Full height of the viewport */
    flex-grow: 1; /* Takes up any remaining space */
    background-color: #efefef; /* Color for visibility */
    flex-direction: column;  /* Aligns children vertically */
    justify-content: space-between;  
}

.right-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ccc;  
    padding-left:15px;
    padding-right: 15px;
    height: 60px;
}

.icon-and-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-side-menu {
    border-right: 1px solid #ddd;
    width: 51px;
    display: flex;
    justify-content: center;
    align-items: start;
}
 
.left-side-menu-item {
   padding:16px;
   text-align: center;
   border-bottom: 1px solid #888;
}
.left-side-menu-item-reverse {
    padding:16px;
    text-align: center;
    border-top: 1px solid #888;
 }
.left-container {
    position: relative;
    width: 33%;
    height: 100%;
    flex: 33% 1;
    background: #fff;
}

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    height: 60px;
    background: #ededed;
    padding-left: 15px;
    padding-right: 25px;
}

.headerMobile {
    text-align: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.nav-icons {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.search-container { 
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;  
    padding-right: 25px; 
    height: 40px;
    background: #f6f6f6;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 15px;
}

.searchbox  {
    height: 30px; 
    width:100%; 
    border-radius: 8px;
    background-color: #ffffff;
    color: #bbb;
    font-size: 0.8em;
    border: none;
    outline: none;
    padding-left: 30px!important;
}

.search-icon {
    position: absolute; /* Positions the icon relative to the nearest positioned ancestor */
    left: 20px; /* Space from the left edge of the input */
    top: 50%; /* Aligns the icon vertically in the middle */
    transform: translateY(-50%); /* Ensures the icon is centered vertically */
    width: 20px; /* Icon width */
    height: 20px; /* Icon height */
    fill: gray; /* Icon color */
    opacity: 0.6; 
}

.send-icon {
    position: absolute; /* Positions the icon relative to the nearest positioned ancestor */
    right: 20px; /* Space from the left edge of the input */
    top: 40%; /* Aligns the icon vertically in the middle */
    transform: translateY(-50%); /* Ensures the icon is centered vertically */
    width: 20px; /* Icon width */
    height: 20px; /* Icon height */
    fill: gray; /* Icon color */
    opacity: 0.6;     
}

.chat-list {
    position: relative;
    height: calc(100% - 100px);
    overflow-y: auto;
}

.chat-list-contacts {
    height: calc(100% - 25px)!important;
}
 
.chat-list .chat-box {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 25px;
    border-bottom: 1px solid #eee;
    background-color: #fcfcfc;
}

.chat-list .chat-box .img-box {
    position: relative;
    width: 63px;
    height: 50px;
}

.profile-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: #fff;
}

.small-flag {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 22px;
    height: 22px;
}

.chat-list .chat-box .chat-details {
    width: 100%;
    margin-left: 15px;
}

.chat-list .active {
  background: #E9E7E6;
}

.chat-list .chat-box:hover {
  background: #f5f5f5;
}

.chat-list .chat-box .chat-details .text-head {
    font-size: 0.8em; 
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
}
.chat-date {
    font-size: 0.8em!important; 
    color:#888!important; 
}

.chat-list .chat-box .chat-details .text-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 20px; 
    font-size: 0.8em; 
    color: #333;
}

.chat-list .chat-box .chat-details p {
    font-size: 0.8em;
    
    color: #333;
}

.chat-list .chat-box .chat-details .text-message b {
    background: #06e744;
    color: #fff;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.8em;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbox-input {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ddd;
}

.chatbox-input i {
  cursor: pointer;
  font-size: 1.8em;
  color: #515855;
}

.chatbox-input-box { 
    width: 100%; 
    padding: 10px 20px;
    border-radius:10px;
    font-size: 1em;
    border:none;
    outline:none;
}

.chatbox-input-mobile {
    position: fixed;
    bottom: 70px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ddd;
    z-index: 9;
}

.chatbox-input-mobile i {
  cursor: pointer;
  font-size: 1.8em;
  color: #515855;
}

.footer-menu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%; /* Ensures the footer spans the full width of the viewport */
    height: 50px; /* Set the height of the footer */
    background-color: #333;
    color: white;
    text-align: center;
    line-height: 50px; /* Centers the text vertically */
    box-shadow: 0 -2px 10px rgba(0,0,0,0.3); /* Adds a shadow for better visibility */
}

.footer-menu-item {
    flex: 1;
}

.footer-menu-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex; /* Flex container for the child divs */
    background-color: #333; /* Light grey background, change as needed */
}
 
.footer-menu-item-mobile {
    flex: 1; /* Each child will take equal space */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: #333; /* Slightly darker grey background for the items, change as needed */
}
 

.chat-container {
    position: relative;
    width: 100%;
    height: calc(100% - 120px);
    padding: 50px;
    overflow-y: auto;
    overflow: 'auto';

    background-image: url('http://localhost:3001/images/backgrounds/background2.png'); /* Arkaplan resmini belirtin */
    background-repeat: repeat; /* Resmi döşeme */
    background-size: cover; /* Resmi kaplayacak şekilde ayarla, tekrar eden desen istenmiyorsa */
 

}

.my-message {
    justify-content: flex-end;
}

.message-box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
}

.message-box p {
    position: relative;
    right: 0;
    text-align: right;
    max-width: 65%;
    padding: 12px;
    background: #dcf8c6;
    border-radius: 10px;
    font-size: 0.9em;
}

.friend-message p {
    background: #fff;
}
.message-box p span {
    display: block;
    margin-top: 5px;
    font-size: 0.8em;
    opacity: 0.5;
}
  

 
/* Login Page */
.myBody {
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    background-color: #f5f5f5; /* Light grey background */
}
.login-form-container {
    width: 33.33%; /* One third of the page width */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 8px;
}
.login-form {
    display: flex;
    flex-direction: column;
}
.form-group {
    display:flex;
    flex:1;
    flex-direction: row;
    margin-bottom: 15px;
}
.login-form h2 {
    color: #333;
    text-align: center;
}
label {
    display: block;
    margin-bottom: 5px;
    color: #666;
    width:250px;
}

textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 120px;
}
input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; 
}
.btn-danger {
    border: none;
    padding: 8px 12px; /* Sufficient padding for button text */
    font-size: 16px; /* Legible font size */
    color: white; /* White text color */
    background-color: #dc3545; /* Vibrant red background */
    border-radius: 4px; /* Rounded corners like Bootstrap */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}
.btn-danger:hover {
    background-color: #c82333; /* Slightly darker red on hover */
}

/* Settings Page  */
.column2-settings {
    height: 100%; /* Each column takes full height of the container */
    width: 100%; /* Width of the second column */
    background-color: #fafafa; /* Color for visibility */
    flex-direction: column;  /* Aligns children vertically */
    align-items: flex-start; /* Aligns items to the start of the flex container */ 
}
 
/* Profile Form  */

.form-double-container {
    display: flex;  /* Establishes a flex container */
    width: 100%;    /* Sets the width of the container to fill its parent */
}

.half-width {
    flex: 1;        /* Each child will grow equally to take up 50% of the container's width */
    text-align: left; /* Centers text horizontally */
    padding: 20px;  /* Adds some padding inside each child div for aesthetics */
    margin: 20px;
    background-color: #ededed;
    border-radius: 10px;
}

.tab-title {
    font-size: 0.95em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
    color:#000;
    text-decoration: none;
}

.settingDescriptions li {
    padding-bottom: 10px;
}

/* FAQ */
.faq-container {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }

  .faq-list {
    border-top: 1px solid #ccc;
  }
  
  .faq-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .faq-question {
    cursor: pointer;
    font-weight: bold;
    color: #333;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 20px;
    color: #666;
  }


table tr td {
    padding-left: 20px;
    padding-bottom: 10px;
}

@media (max-width: 768px) {
    .column1 {
        display: none; /* Hide both by default */
    }
    .column2 {
        height: 100%; /* Each column takes full height of the container */
        flex-grow: 1; /* Takes up any remaining space */
        background-color: #fafafa; /* Color for visibility */
    }
    .column3 {
        display: none; /* Hide both by default */
    }
    input, select, textarea {
        font-size: 16px; /* Ensures readability without zooming */
        width: 100%; /* Adjusts input width to device width */
        padding: 10px; /* Adds padding for better touch interaction */
    }
    .login-form-container {
        width: 80%;
    }
}